import type { OfferListingFragment } from '@dx-ui/content-offers-components';
import {
  sendReward,
  Status,
  useConductricsSelection,
  useInitConductrics,
} from '@dx-ui/framework-conductrics';
import { useRef, useEffect, useCallback } from 'react';
import { useUserSegments } from '@dx-ui/framework-user-segments';
import { useAuth } from '@dx-ui/framework-auth-provider';

const mappingOfSegmentsToTraits = [
  ['24770528', 'new-existing-members:new-members'],
  ['24770536', 'new-existing-members:existing-members'],
  ['24770542', 'email-marketable:email-marketable'],
  ['24770544', 'email-marketable:not-email-marketable'],
  ['24770561', 'stays-in-past-year:2plus-stays'],
  ['24770573', 'user-points:no-points'],
  ['24770760', 'user-points:10k-points-or-more'],
  ['24770578', 'has-app:no'],
];

export function useInitializeConductrics() {
  const isConductricsInit = useRef(false);
  const initConductrics = useInitConductrics();
  const userSegments = useUserSegments();
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();

  useEffect(() => {
    if (!isConductricsInit.current && !isLoadingAuth) {
      isConductricsInit.current = true;

      const traits: string[] = [`login:${isAuthenticated ? 'yes' : 'no'}`];
      mappingOfSegmentsToTraits.forEach(([segmentId, traitName]) => {
        if (userSegments.includes(segmentId)) {
          traits.push(traitName);
        }
      });
      initConductrics({ traits });
    }
  }, [initConductrics, isAuthenticated, isLoadingAuth, userSegments]);
}

function moveToStart<T>(array: T[], predicate: (item: T) => boolean): T[] {
  return array.sort((a, b) => {
    if (predicate(a)) return -1;
    if (predicate(b)) return 1;

    return 0;
  });
}

const conductricsAgentId = 'a-HR8xA6YdnApq';

export function useConductricsSortOrder() {
  const { selection: selection5467, isLoaded: is5467Loaded } = useConductricsSelection(
    'a-zeS0mq5FwoWy',
    Status.OK
  );
  const { selection, isLoaded } = useConductricsSelection(conductricsAgentId, Status.OK);

  // metadata from conductrics test
  const firstItemId = selection?.meta?.offerId;

  return useCallback(
    (offers: OfferListingFragment['offers']) => {
      const offerIdsToHide = (selection5467?.meta?.offerIds as string)?.split(' ') || [];

      const offersFilteredByTitle = offers.filter((offer) => {
        if (selection5467?.choice === 'B' && offerIdsToHide.includes(offer.id.toString())) {
          return false;
        }

        return true;
      });

      if (firstItemId && isLoaded && is5467Loaded) {
        return moveToStart(
          offersFilteredByTitle,
          (offer: OfferListingFragment['offers'][number]) => offer.id === firstItemId
        );
      }

      return offersFilteredByTitle;
    },
    [firstItemId, isLoaded, is5467Loaded, selection5467]
  );
}

export function sendCTAClickedOfferId(offerId: number) {
  // generic reward/goal for clicking on CTA
  sendReward('g-KjZz9kisK0');
  const rewardId =
    mappingOfferIdToConductricsGoal[offerId as keyof typeof mappingOfferIdToConductricsGoal];
  if (rewardId) {
    sendReward(rewardId);
  }
}

const mappingOfferIdToConductricsGoal = {
  // cobrand
  212: 'g-BBxRCYBO8W',
  2000000057: 'g-BBxRCYBO8W',

  // 2x points
  251: 'g-g4snLVwb1o',
  2000000046: 'g-g4snLVwb1o',

  // Mass Promo (aka 3x points) CTA
  205: 'g-JGLgPSn0nw',
  2000000257: 'g-JGLgPSn0nw',

  // Honors Discount Advanced Purchase
  237: 'g-imSqsldPyH',
  2000000047: 'g-imSqsldPyH',
};
