import { slugifyOfferName } from '../utils/slugify-offer';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { addCampaignId, type OfferListingFragment } from '@dx-ui/content-offers-components';

type Offer = OfferListingFragment['offers'][0];

export function ViewDetailsLink({
  offer,
  hrefPrefix,
  onClick,
}: {
  offer: Offer;
  hrefPrefix: string;
  onClick?: (offer: Offer) => void;
}) {
  const { t } = useTranslation('offers');
  return (
    <Link
      href={addCampaignId(`${hrefPrefix}${slugifyOfferName(offer)}`)}
      onClick={() => onClick?.(offer)}
      className="btn btn-lg btn-primary w-full text-center md:w-auto"
      data-offer-slug={slugifyOfferName(offer)}
      data-offer-name={offer.name}
      aria-label={t('viewDetailsA11y', {
        offerName: offer?.name || '',
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('viewDetails')}
      <span className="sr-only">{t('opensInNewTab')}</span>
    </Link>
  );
}
